<template>
	<div>
		<s-header name="搜索"></s-header>
		<van-search :clearable="false" shape="round" v-model="searchText" show-action placeholder="搜索你需要的商品热门关键词" @search="onClickButton">
			<template #action>
				<button class="button" @click="onClickButton">搜索</button>
			</template>
		</van-search>
		<div>
			<p class="tit">历史搜索</p>
			<div class="history_search_list">
				<div class="history_item" v-for="item,index in historyList" :key="index" @click="onHisItem(item)">
					{{item}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		toRefs,
		reactive,
		onMounted
	} from 'vue'
	import {
		useRouter
	} from 'vue-router'
	import {
		Toast
	} from 'vant'
	import {
		setLocal,
		getLocal
	} from '@/common/js/utils'
	import sHeader from '@/components/SimpleHeader'
	import goodItem from '@/components/GoodItem'
	export default {
		components: {
			sHeader,
			goodItem
		},
		setup() {
			const router = useRouter()
			const state = reactive({
				searchText: '',
				historyList: []
			})
			onMounted(() => {
				getHistory();
			})
			const onClickButton = () => {
				if(!state.searchText) return Toast('请输入搜索内容');
				setHistory();
				router.push({
					path: `/search-list`,
					query: {
						keyword: state.searchText
					}
				})
			}
			const onHisItem = (val) => {
				state.searchText = val;
				setHistory();
				router.push({
					path: `/search-list`,
					query: {
						keyword: state.searchText
					}
				})
			}
			//获取搜索记录
			const getHistory = () => {
				var history_val = getLocal('history');
				if(history_val) {
					state.historyList = JSON.parse(history_val);
				}
			}
			//添加搜索记录
			const setHistory = () => {
				if(!state.historyList.includes(state.searchText)) {
					state.historyList.unshift(state.searchText);
				} else {
					let i = state.historyList.indexOf(state.searchText);
					state.historyList.splice(i, 1);
					state.historyList.unshift(state.searchText)
				}
				setLocal('history', JSON.stringify(state.historyList))
			}
			return {
				...toRefs(state),
				onClickButton,
				onHisItem
			}
		}
	}
</script>

<style scoped lang="less">
	.button {
		width: 60px;
		height: 30px;
		line-height: 30px;
		background: linear-gradient(180deg, #e9192e, #fc5d3e);
		border-radius: 30px;
		color: #ffffff;
		border: none;
		font-size: 14px;
	}
	.tit{
		padding-left: 20px;
		margin: 25px 0 0 0;
	}
	.history_search_list {
		box-sizing: border-box;
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.history_item{
			margin-right: 10px;
			padding: 0 20px;
			height: 30px;
			line-height: 30px;
			background: #f2f2f2;
			border-radius: 20px;
			margin-bottom: 10px;
		}
	}
</style>
